import { requestActions } from 'store/actions';
import errorHandler from 'components/Error/ErrorHandler';

export const makeRequest = (actionType, requestCall) => async (dispatch) => {
  dispatch({
    type: requestActions.REQUEST,
    actionType,
  });
  try {
    const data = await requestCall();
    dispatch({
      type: requestActions.SUCCESS,
      actionType,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: requestActions.FAILED,
      error,
    });

    if (error && error.response && error.response.status) {
      // flashes the appropriate error message & redirect if necessary
      const { response: { status, data } } = error;
      return errorHandler(dispatch, status, data);
    }

    throw error.response;
  }
};
