import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

// Sentry.init({
//   dsn: "https://36d2061667be4e619fed98305d6baa29@o147643.ingest.sentry.io/1193619",
//   autoSessionTracking: true,
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
