export const sessionActions = {
  LOGOUT: 'SESSION/LOGOUT',
};

export const flashMessageActions = {
  SHOW_MESSAGE: 'FLASH_MESSAGE/SHOW',
  HIDE_MESSAGE: 'FLASH_MESSAGE/HIDE',
};

export const requestActions = {
  REQUEST: 'REQUEST/REQUEST',
  FAILED: 'REQUEST/FAILED',
  SUCCESS: 'REQUEST/SUCCESS',
};

export const userActions = {
  SET_CURRENT_USER_INFO: 'USER/SET_CURRENT_USER_INFO',
  SET_AVATAR: 'USER/SET_AVATAR',
  CREATE_USER: 'USER/CREATE_USER',
  UPDATE_USER: 'USER/UPDATE_USER',
  FETCH_USER: 'USER/FETCH_USER',
  FETCH_ALL_USERS: 'USER/FETCH_ALL_USERS',
  DELETE_USER: 'USER/DELETE_USER',
  CHANGE_PASSWORD: 'USER/CHANGE_PASSWORD',
  TOGGLE_COMMENTS: 'USER/TOGGLE_COMMENTS',
  SEARCH_USERS: 'USER/SEARCH_USERS',
};

export const organizationActions = {
  FETCH_ALL_ORGANIZATIONS: 'ORGANIZATION/FETCH_ALL_ORGANIZATIONS',
  CREATE_ORGANIZATION: 'ORGANIZATION/CREATE_ORGANIZATION',
  FETCH_ORGANIZATION: 'ORGANIZATION/FETCH_ORGANIZATION',
  UPDATE_ORGANIZATION: 'ORGANIZATION/UPDATE_ORGANIZATION',
  DELETE_ORGANIZATION: 'ORGANIZATION/DELETE_ORGANIZATION',
  CREATE_ORGANIZATION_USER: 'ORGANIZATION/CREATE_ORGANIZATION_USER',
};

export const orderActions = {
  FETCH_ALL_ORDERS: 'ORDER/FETCH_ALL_ORDERS',
  CREATE_ORDER: 'ORDER/CREATE_ORDER',
  UPDATE_ORDER: 'ORDER/UPDATE_ORDER',
  FETCH_ORDER: 'ORDER/FETCH_ORDER',
  DELETE_ORDER: 'ORDER/DELETE_ORDER',
  FETCH_BOL_ORDERS: 'ORDER/FETCH_BOL_ORDERS',
  FETCH_PRESTASHOP_ORDERS: 'ORDER/FETCH_PRESTASHOP_ORDERS',
  PRINT_ORDERS: 'ORDER/PRINT_ORDERS',
};

export const strategyMatrixActions = {
  FETCH_ALL_STRATEGY_MATRICES: 'STRATEGY_MATRIX/FETCH_ALL_STRATEGY_MATRICES',
  CREATE_STRATEGY_MATRIX: 'STRATEGY_MATRIX/CREATE_STRATEGY_MATRIX',
  UPDATE_STRATEGY_MATRIX: 'STRATEGY_MATRIX/UPDATE_STRATEGY_MATRIX',
  FETCH_STRATEGY_MATRIX: 'STRATEGY_MATRIX/FETCH_STRATEGY_MATRIX',
  DELETE_STRATEGY_MATRIX: 'STRATEGY_MATRIX/DELETE_STRATEGY_MATRIX',
};

export const portfolioActions = {
  FETCH_ALL_PORTFOLIOS: 'PORTFOLIO/FETCH_ALL_PORTFOLIOS',
  CREATE_PORTFOLIO: 'PORTFOLIO/CREATE_PORTFOLIO',
  UPDATE_PORTFOLIO: 'PORTFOLIO/UPDATE_PORTFOLIO',
  FETCH_PORTFOLIO: 'PORTFOLIO/FETCH_PORTFOLIO',
  OPTIMIZE_PORTFOLIO: 'PORTFOLIO/OPTIMIZE_PORTFOLIO',
  DELETE_PORTFOLIO: 'PORTFOLIO/DELETE_PORTFOLIO',
  PROVISION_DEMO_CONTENT: 'PORTFOLIO/PROVISION_DEMO_CONTENT',
};

export const projectMatricesActions = {
  FETCH_ALL_PROJECT_MATRICES: 'PROJECT_MATRIX/FETCH_ALL_PROJECT_MATRICES',
  CREATE_PROJECT_MATRIX: 'PROJECT_MATRIX/CREATE_PROJECT_MATRIX',
  UPDATE_PROJECT_MATRIX: 'PROJECT_MATRIX/UPDATE_PROJECT_MATRIX',
  FETCH_PROJECT_MATRIX: 'PROJECT_MATRIX/FETCH_PROJECT_MATRIX',
  DELETE_PROJECT_MATRIX: 'PROJECT_MATRIX/DELETE_PROJECT_MATRIX',
};

export const strategyActions = {
  FETCH_ALL_STRATEGIES: 'STRATEGY/FETCH_ALL_STRATEGIES',
  CREATE_STRATEGY: 'STRATEGY/CREATE_STRATEGY',
  UPDATE_STRATEGY: 'STRATEGY/UPDATE_STRATEGY',
  UPDATE_STRATEGY_COMPARISON: 'STRATEGY/UPDATE_STRATEGY_COMPARISON',
  FETCH_STRATEGY: 'STRATEGY/FETCH_STRATEGY',
  DELETE_STRATEGY: 'STRATEGY/DELETE_STRATEGY',
  CREATE_COMMENT: 'STRATEGY/CREATE_COMMENT',
  DELETE_COMMENT: 'STRATEGY/DELETE_COMMENT',
};

export const strategyComparisonActions = {
  FETCH_ALL_STRATEGY_COMPARISONS: 'STRATEGY/FETCH_ALL_STRATEGY_COMPARISONS',
  CREATE_STRATEGY: 'STRATEGY/CREATE_STRATEGY_COMPARISON',
  UPDATE_STRATEGY_COMPARISON: 'STRATEGY/UPDATE_STRATEGY_COMPARISON',
  FETCH_STRATEGY: 'STRATEGY/FETCH_STRATEGY_COMPARISON',
};

export const projectActions = {
  FETCH_ALL_PROJECTS: 'PROJECT/FETCH_ALL_PROJECTS',
  CREATE_PROJECT: 'PROJECT/CREATE_PROJECT',
  UPDATE_PROJECT: 'PROJECT/UPDATE_PROJECT',
  UPDATE_PROJECT_COMPARISON: 'PROJECT/UPDATE_PROJECT_COMPARISON',
  FETCH_PROJECT: 'PROJECT/FETCH_PROJECT',
  DELETE_PROJECT: 'PROJECT/DELETE_PROJECT',
  UPLOAD_PROJECT_FILE: 'PROJECT/UPLOAD_PROJECT_FILE',
  SIMULATE_PROJECT: 'PROJECT/SIMULATE_PROJECT',
  FETCH_NODES: 'PROJECT/FETCH_NODES',
  UPDATE_NODE: 'PROJECT/UPDATE_NODE',
  CREATE_NODE: 'PROJECT/CREATE_NODE',
  DELETE_NODE: 'PROJECT/DELETE_NODE',
  FETCH_TASKS: 'PROJECT/FETCH_TASKS',
  CREATE_TASK: 'PROJECT/CREATE_TASK',
  UPDATE_TASK: 'PROJECT/UPDATE_TASK',
  DELETE_TASK: 'PROJECT/DELETE_TASK',
  CREATE_COMMENT: 'PROJECT/CREATE_COMMENT',
  DELETE_COMMENT: 'PROJECT/DELETE_COMMENT',
  FETCH_NODE_EVENTS: 'PROJECT/FETCH_NODE_EVENTS',
  CREATE_NODE_EVENT: 'PROJECT/CREATE_NODE_EVENT',
  UPDATE_NODE_EVENT: 'PROJECT/UPDATE_NODE_EVENT',
  DELETE_NODE_EVENT: 'PROJECT/DELETE_NODE_EVENT',
};

export const attachmentActions = {
  UPDATE_ATTACHMENTS: 'ATTACHMENTS/UPDATE_ATTACHMENTS',
  DELETE_ATTACHMENTS: 'ATTACHMENTS/DELETE_ATTACHMENTS',
};

export const riskActions = {
  FETCH_ALL_RISKS: 'RISK/FETCH_ALL_RISKS',
  CREATE_RISK: 'RISK/CREATE_RISK',
  UPDATE_RISK: 'RISK/UPDATE_RISK',
  DELETE_RISK: 'RISK/DELETE_RISK',
  CREATE_COMMENT: 'RISK/CREATE_COMMENT',
  DELETE_COMMENT: 'RISK/DELETE_COMMENT',
};

export const taskActions = {
  FETCH_ALL_PROJECT_TASKS: 'TASK/FETCH_ALL_PROJECT_TASKS',
  FETCH_ALL_TASKS: 'TASK/FETCH_ALL_TASKS',
  CREATE_TASK: 'TASK/CREATE_TASK',
  UPDATE_TASK: 'TASK/UPDATE_TASK',
  DELETE_TASK: 'TASK/DELETE_TASK',
  UPDATE_TASK_COMPARISON: 'TASK/UPDATE_TASK_COMPARISON',
  CREATE_COMMENT: 'TASK/CREATE_COMMENT',
  DELETE_COMMENT: 'TASK/DELETE_COMMENT',
};
