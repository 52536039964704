const initState = () => ({
  user: {},
  token: {
    auth_token: false,
  },
  is_authenticated: false,
  status: {
    signup: 'init',
    login: 'init',
    logout: 'init',
    change_password: 'init',
  },
  error: {
    signup: null,
    login: null,
    logout: null,
    change_password: null,
  },
});

const getUser = (state, key, val) => ({
  ...state.user,
  ...{ [key]: val },
});

const getStatus = (state, key, val) => ({
  ...initState().status,
  ...{ [key]: val },
});

const getError = (state, key, val) => ({
  ...state.error,
  ...{ [key]: val },
});

export default function auth(state = initState(), action = {}) {
  switch (action.type) {
    // case 'AUTH_INIT':
    //   return {
    //     ...state,
    //     ...{
    //       user: action.payload.user || {},
    //       userSub: action.payload.sub,
    //     },
    //   };
    // case 'AUTH_SET_INIT_VAL':
    //   return {
    //     ...state,
    //     ...{ status: getStatus(state, action.payload, 'init') },
    //   };
    case 'AUTH_GET_CURRENT_USER': {
      const user = action.payload;

      return {
        ...state,
        user,
      };
    }
    case 'AUTH_SIGNUP_PENDING':
      return {
        ...state,
        ...{
          status: getStatus(state, 'signup', 'pending'),
          error: getError(state, 'signup', null),
        },
      };
    case 'AUTH_SIGNUP_SUCCESS':
      return {
        ...state,
        ...{
          user: action.payload || {},
          status: getStatus(state, 'signup', 'success'),
          error: getError(state, 'signup', null),
        },
      };
    case 'AUTH_SIGNUP_FAIL':
      return {
        ...state,
        ...{
          status: getStatus(state, 'signup', 'fail'),
          error: getError(state, 'signup', action.payload),
        },
      };
    case 'AUTH_LOGIN_PENDING':
      if (action.payload === false) {
        return state;
      }
      return {
        ...state,
        ...{
          status: getStatus(state, 'login', 'pending'),
          error: getError(state, 'login', null),
        },
      };
    case 'AUTH_LOGIN_SUCCESS': {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...{
          user: action.payload.user || {},
          is_authenticated: true,
          token: {
            auth_token: action.payload.access_token,
          },
          status: getStatus(state, 'login', 'success'),
          error: getError(state, 'login', null),
        },
      };
    }
    case 'AUTH_LOGIN_FAIL':
      console.log(state, 'login failed');
      return {
        ...state,
        ...{
          user: {},
          status: getStatus(state, 'login', 'fail'),
          error: getError(state, 'login', action.payload),
        },
      };
    case 'AUTH_LOGOUT_PENDING':
      if (action.payload === false) {
        return state;
      }
      return {
        ...state,
        ...{
          status: getStatus(state, 'logout', 'pending'),
          error: getError(state, 'logout', null),
        },
      };
    case 'AUTH_LOGOUT_SUCCESS':
      return {
        ...state,
        ...{
          user: {},
          is_authenticated: false,
          token: {
            auth_token: false,
          },
          status: getStatus(state, 'logout', 'success'),
          error: getError(state, 'logout', null),
        },
      };
    case 'AUTH_LOGOUT_FAIL':
      return {
        ...state,
        ...{
          status: getStatus(state, 'logout', 'fail'),
          error: getError(state, 'logout', action.payload),
        },
      };
    case 'AUTH_CHANGE_PASSWORD_PENDING':
      if (action.payload === false) {
        return state;
      }
      return {
        ...state,
        ...{
          status: getStatus(state, 'change_password', 'pending'),
          error: getError(state, 'change_password', null),
        },
      };
    case 'AUTH_CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        ...{
          status: getStatus(state, 'change_password', 'success'),
          error: getError(state, 'change_password', null),
        },
      };
    case 'AUTH_CHANGE_PASSWORD_FAIL':
      return {
        ...state,
        ...{
          status: getStatus(state, 'change_password', 'fail'),
          error: getError(state, 'change_password', action.payload),
        },
      };
    case 'AUTH_AVATAR_UPLOAD_PENDING':
      if (action.payload === false) {
        return state;
      }
      return {
        ...state,
        ...{
          status: getStatus(state, 'upload_avatar', 'pending'),
          error: getError(state, 'upload_avatar', null),
        },
      };
    case 'AUTH_AVATAR_UPLOAD_SUCCESS':
      const { avatar } = action.payload;
      return {
        ...state,
        ...{
          user: getUser(state, 'avatar', avatar),
          status: getStatus(state, 'upload_avatar', 'success'),
          error: getError(state, 'upload_avatar', null),
        },
      };
    case 'AUTH_AVATAR_UPLOAD_FAIL':
      return {
        ...state,
        ...{
          status: getStatus(state, 'upload_avatar', 'fail'),
          error: getError(state, 'upload_avatar', action.payload),
        },
      };
    case 'AUTH_SAVE_USER_PENDING':
      if (action.payload === false) {
        return state;
      }
      return {
        ...state,
        ...{
          status: getStatus(state, 'save_user', 'pending'),
          error: getError(state, 'save_user', null),
        },
      };
    case 'AUTH_SAVE_USER_SUCCESS':
      return {
        ...state,
        ...{
          status: getStatus(state, 'save_user', 'success'),
          error: getError(state, 'save_user', null),
        },
      };
    case 'AUTH_SAVE_USER_FAIL':
      return {
        ...state,
        ...{
          status: getStatus(state, 'save_user', 'fail'),
          error: getError(state, 'save_user', action.payload),
        },
      };
    case 'RESET_STORE': {
      const newState = initState();
      return {
        ...newState,
      };
    }
    default:
      return state;
  }
}
