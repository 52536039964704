import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';
import session from '../session/session.reducer';
import auth from '../auth/auth.reducer';
import { requestReducer } from '../requests/requests.reducer';
import { entitiesReducer } from '../entities/entities.reducer';
import { flashMessagesReducer } from '../ui/flashMessages.reducer';

import organizations from '../organizations/organizations.reducer';
import { userReducer } from '../users/users.reducer';
import { strategyReducer } from '../strategy/strategy.reducer';
import { strategyComparisonReducer } from '../strategyComparisons/strategyComparison.reducer';
import { projectReducer } from '../project/project.reducer';
import { portfolioReducer } from '../portfolio/portfolio.reducer';
import { orderReducer } from '../order/order.reducer';
import { riskReducer } from '../risk/risk.reducer';
import { taskReducer } from '../task/task.reducer';
import projectPriority from '../projectPriorities/projectPriority.reducer';
import GDPR from '../GDPR/gdpr.reducer';

export default (history) =>
  combineReducers({
    auth,
    entities: entitiesReducer,
    session,
    router: connectRouter(history),
    flashMessages: flashMessagesReducer,
    requests: requestReducer,
    organizations,
    // kpiRegistration,
    users: userReducer,
    portfolios: portfolioReducer,
    orders: orderReducer,
    strategy: strategyReducer,
    strategyComparison: strategyComparisonReducer,
    project: projectReducer,
    GDPR,
    projectPriority,
    risks: riskReducer,
    tasks: taskReducer,
  });
