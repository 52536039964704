import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { FormPageLayout } from 'layouts';
import { AuthGuardFunctional, AuthGuardMemberFunctional } from 'components';
import { FormattedMessage } from 'react-intl';
import { NewDashboardLayout } from 'layouts/Dashboard';

const LandingPage = lazy(() => import('pages/Landing'));
const LogIn = lazy(() => import('pages/LogIn/LogIn'));
const SignUp = lazy(() => import('pages/SignUp/SignUp'));
const PasswordReset = lazy(() => import('pages/PasswordReset/PasswordReset'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword/ForgotPassword'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const SettingsRoutes = lazy(() => import('pages/Settings/SettingsRoutes'));
const OrganizationsRoutes = lazy(() =>
  import('pages/Organizations/OrganizationsRoutes')
);
const OrganizationsDetailRoutes = lazy(() =>
  import('pages/Organizations/OrganizationsDetailRoutes')
);
const UsersRoutes = lazy(() => import('pages/Users/UsersRoutes'));
const UserDetailRoutes = lazy(() => import('pages/Users/UsersDetailRoutes'));
const GDPRpolicyRoutes = lazy(() => import('pages/GDPRpolicy/GDPRpolicyRoutes'));
const OrdersRoutes = lazy(() => import('pages/Orders/OrderListPage'));

const PortfolioAdminPage = lazy(() => import('pages/Admin/Portfolio/PortfolioAdmin'));
const ProjectAdminPage = lazy(() => import('pages/Admin/Project/ProjectAdmin'));

const withDashboardAdmin = (ContentComponent) => {
  return (props) => (
    <AuthGuardFunctional>
      <NewDashboardLayout>
        <ContentComponent {...props} />
      </NewDashboardLayout>
    </AuthGuardFunctional>
  );
};

const withDashboardMember = (ContentComponent) => {
  return (props) => (
    <AuthGuardMemberFunctional>
      <NewDashboardLayout>
        <ContentComponent {...props} />
      </NewDashboardLayout>
    </AuthGuardMemberFunctional>
  );
};

const OrganizationsComponent = withDashboardAdmin(OrganizationsRoutes);
const OrganizationsDetailComponent = withDashboardAdmin(
  OrganizationsDetailRoutes
);
const UsersComponent = withDashboardAdmin(UsersRoutes);
const UserDetailComponent = withDashboardAdmin(UserDetailRoutes);
const PortfolioAdminComponent = withDashboardAdmin(PortfolioAdminPage);
const ProjectAdminComponent = withDashboardAdmin(ProjectAdminPage);

const SettingsWithDashboard = withDashboardMember(SettingsRoutes);
const GDPRpolicyComponent = withDashboardMember(GDPRpolicyRoutes);
const OrdersComponent = withDashboardMember(OrdersRoutes);

export const Routes = () => {
  const isAuthenticated = useSelector((state) => state.auth.is_authenticated);

  return (
    <Switch>
      <Route
        exact
        path="/login"
        render={() => (isAuthenticated ? <Redirect to="/orders" /> : (
          <FormPageLayout
            title={<FormattedMessage id="titles.login" defaultMessage="Log In"/>}
          >
            <LogIn />
          </FormPageLayout>
        ))}
      />
      <Route
        exact
        path="/signup"
        render={() => (isAuthenticated ? <Redirect to="/orders" /> : (
          <FormPageLayout
            title={
              <FormattedMessage id="titles.signup" defaultMessage="Sign Up"/>
            }
          >
            <SignUp />
          </FormPageLayout>
        ))}
      />
      <Route
        exact
        path="/forgot-password"
        render={() => (
          <FormPageLayout
            title={(
              <FormattedMessage
                id="titles.forgot.password"
                defaultMessage="Forgot Password"
              />
            )}
          >
            <ForgotPassword />
          </FormPageLayout>
        )}
      />
      <Route
        exact
        path="/reset-password"
        render={(props) => (
          <FormPageLayout
            title={(
              <FormattedMessage
                id="titles.reset.password"
                defaultMessage="Reset Password"
              />
            )}
          >
            <PasswordReset {...props} />
          </FormPageLayout>
        )}
      />

      {/* Member Routes */}
      <Route exact path="/" component={LandingPage} />
      <Route path="/settings" component={SettingsWithDashboard}/>
      <Route exact path="/gdpr-policy" component={GDPRpolicyComponent}/>
      <Route exact path="/orders" component={OrdersComponent} />

      {/* Admin Routes */}
      <Route
        exact
        path="/admin/organizations"
        component={OrganizationsComponent}
      />
      <Route
        exact
        path="/admin/organizations/:id"
        component={OrganizationsDetailComponent}
      />
      <Route
        exact
        path="/admin/organizations/:id/edit"
        component={OrganizationsDetailComponent}
      />
      <Route exact path="/admin/users" component={UsersComponent} />
      <Route exact path="/admin/users/add" component={UserDetailComponent} />
      <Route path="/admin/users/:id" component={UserDetailComponent} />

      <Route exact path="/admin/portfolios" component={PortfolioAdminComponent} />
      <Route exact path="/admin/projects" component={ProjectAdminComponent} />

      {/* 404 route */}
      <Route path="*" exact render={() => <NotFound />} />
    </Switch>
  );
};
