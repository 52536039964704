import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Controller } from 'react-hook-form';
import {
  FormControlLabel,
  InputLabel,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import './FormLine.scss';
import { FormattedMessage, useIntl } from 'react-intl';

export const FormLine = ({
  children,
  name,
  margin = 'normal',
  error,
  ...custom
}) => {
  console.log(error)
  return (
    <div htmlFor={name}>
      <TextField
        error={!!error}
        name={name}
        variant="outlined"
        margin={margin}
        fullWidth
        {...custom}
        helperText={
          error && error.message && error.message.id ? (
            <FormattedMessage
              id={error.message.id}
              defaultMessage={error.message.defaultMessage}
            />
          ) : (
            ''
          )
        }
      >
        {children}
      </TextField>
    </div>
  );
}

export const NumberFLine = ({
  intl,
  labelText,
  name,
  children,
  className = '',
  error,
  input,
  ...custom
}) => (
  <div htmlFor={name}>
    <TextField
      label={labelText}
      name={name}
      type="number"
      margin="normal"
      variant="outlined"
      fullWidth
      error={!!error}
      helperText={error ? intl.formatMessage(error) : ''}
      {...input}
      {...custom}
    />
    {children}
    {/* {touched && */}
    {/*  (error && <div className="text-red text-sm">{typeof error !== 'string' ? intl.formatMessage(error) : error}</div>)} */}
  </div>
);

export const SwitchLine = ({
  intl,
  labelText,
  name,
  checked,
  value,
  onChange,
  children,
  className='',
  meta: { touched, error },
  input,
  ...custom
}) => (
  <div htmlFor={name}>
    <FormControlLabel
      control={
        <Switch
          label={labelText}
          name={name}
          checked={!!value}
          onChange={onChange}
          color="primary"
          className="SwitchOption"
          {...input}
          {...custom}
        />
      }
      label={labelText}
      className="SwitchOptionLabel"
    />
    {children}
    {touched && error && (
      <div className="text-red text-sm">
        {typeof error !== 'string' ? intl.formatMessage(error) : error}
      </div>
    )}
  </div>
);

export const CheckboxLine = ({
  intl,
  labelText,
  name,
  checked,
  value,
  onChange,
  children,
  className = '',
  meta: { touched, error },
  input,
  ...custom
}) => (
  <div htmlFor={name}>
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={checked ? !!checked : false}
          onChange={onChange}
          color="primary"
          {...input}
          {...custom}
        />
      }
      label={labelText}
    />
    {children}
    {touched && error && (
      <div className="text-red text-sm">
        {typeof error !== 'string' ? intl.formatMessage(error) : error}
      </div>
    )}
  </div>
);

export const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  ...props
}) => {
  const labelId = `${name}-label`;
  return (
    <div style={{marginTop: '10px'}}>
      <InputLabel>{label}</InputLabel>
      <Controller
        as={
          <Select label={label} fullWidth>
            {children}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
};

export const DropdownLine = ({
  name,
  label='',
  onChange,
  children,
  defaultValue,
  error,
  classes = '',
  margin = 'normal',
  size = 'medium',
  control,
  ...props
}) => (
  <Controller
    control={control}
    as={
      <TextField
        fullWidth
        select
        name={name}
        variant="outlined"
        error={!!error}
        size="small"
        helperText={
          error ? (
            <FormattedMessage
              id={error.message.id}
              defaultMessage={error?.message.defaultMessage}
            />
          ) : (
            ''
          )
        }
        {...props}
      >
        {children}
      </TextField>
    }
    name={name}
    control={control}
    defaultValue={defaultValue}
    {...props}
  />
);

export const PasswordFLine = ({
  intl,
  labelText,
  name,
  children,
  className = '',
  meta: { touched, error },
  input,
  ...custom
}) => (
  <div htmlFor={name}>
    <TextField
      label={labelText}
      type="password"
      margin="normal"
      variant="outlined"
      fullWidth
      error={!!(error && touched)}
      helperText={error && touched ? intl.formatMessage(error) : ''}
      {...input}
      {...custom}
    />
    {children}
    {/* {touched && */}
    {/*  (error && <div className="text-red text-sm">{typeof error !== 'string' ? intl.formatMessage(error) : error}</div>)} */}
  </div>
);

export const TextFormLine = ({ input, ...wrapperProps }) => {
  const intl = useIntl();
  return <FormLine {...wrapperProps} intl={intl} {...input} />;
};

export const NumberFormLine = ({ input, ...wrapperProps }) => {
  const intl = useIntl();
  return <NumberFLine {...wrapperProps} intl={intl} {...input} />;
};

export const PasswordFormLine = ({ input, ...wrapperProps }) => {
  const intl = useIntl();
  return <PasswordFLine intl={intl} {...wrapperProps} {...input} />;
};

export const TextAreaFormLine = ({ input, ...wrapperProps }) => {
  const intl = useIntl();
  return <FormLine intl={intl} {...wrapperProps} {...input} />;
};

export const SwitchFormLine = ({ input, ...wrapperProps }) => {
  const intl = useIntl();
  return <SwitchLine intl={intl} {...wrapperProps} {...input} />;
};

export const CheckboxFormLine = ({ input, ...wrapperProps }) => {
  const intl = useIntl();
  return <CheckboxLine intl={intl} {...wrapperProps} {...input} />;
};

export const DropdownFormLine = ({ input, ...wrapperProps }) => {
  const intl = useIntl();
  return <DropdownLine intl={intl} {...wrapperProps} {...input} />;
};
