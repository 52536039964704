import {
  clone,
  each,
  has,
  isArray,
  unionBy,
} from 'lodash';

export const createReducer = (initialState, handlers) => {
  if (!handlers.hasOwnProperty('RESET_STORE')) {
      handlers.RESET_STORE = resetStore(initialState);
  }

  return (state = initialState, action) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }

    return state;
  };
};

export const initState = () => ({
  pending: false,
  list: [],
  item: {},
  error: null,
});

export const createEntry = (key) => (state, { payload }) => {
  if (!payload) return { ...state };

  return {
    ...state,
    ...{
      [key]: [...state[key], payload],
    },
  };
};

export const deleteEntry = (key) => (state, { payload }) => {
  if (!payload) return { ...state };

  return {
    ...state,
    [key]: state[key].filter((item) => item.id !== payload.id),
  };
};

export const updateEntry = (state, { payload }) => {
  let payloadClone = clone(payload);

  if (has(payloadClone, 'data')) {
    payloadClone = payloadClone.data;
  }

  each(payloadClone, (property, propertyName) => {
    if (isArray(property)) {
      payloadClone[propertyName] = [];
    }
  });

  if (state.list && state.list.length > 0) {
    payloadClone = unionBy(Array.isArray(payloadClone) ? payloadClone : [payloadClone], state.list, 'id');
  }

  return {
    ...state,
    ...{
      list: isArray(payloadClone) ? payloadClone : [payloadClone],
    },
  };
};

export const resetStore = (initialState) => () => {
  const newState = initialState || initState();
  return {
    ...newState,
  };
};

export const updateSingleEntry = (key) => (state, { payload }) => {
  if (!payload) return { ...state };

  const newItems = state[key].map((item) => {
    if (item.id === payload.id) {
      return {
        ...item,
        ...payload,
      };
    }

    return item;
  });

  return {
    ...state,
    [key]: newItems,
  };
};

export const updateKey = (key) => (state, { payload }) => {
  if (!payload) return { ...state };

  return {
    ...state,
    [key]: isArray(payload) ? payload : [payload],
  };
};

export const createKey = (key) => (state, { payload }) => {
  if (!payload) return { ...state };

  return {
    ...state,
    [key]: [...state[key], payload],
  };
};

export const updateKeyList = (key) => (state, { payload }) => {
  if (!has(payload, key)) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    ...{
      list: payload[key],
    },
  };
};

export const updateMergeLists = (key) => (state, { payload }) => {
  if (!has(payload, key)) return { ...state };

  const mergedLists = unionBy(payload[key], state.list, 'id');

  return {
    ...state,
    list: mergedLists,
  };
};
