import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  circle: {
    width: 35,
    height: 35,
    borderRadius: 100,
    position: 'absolute',
    zIndex: 1,
  },
  circleDark: {
    background: theme.palette.secondary.main,
    opacity: '60%',
    left: 20,
  },
  circleLight: {
    background: theme.palette.secondary.main,
    opacity: '30%',
    left: 40,
  },
  text: {
    marginLeft: 15,
    zIndex: 2,
  },
}));

export const Logo = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <div className={`${classes.circle} ${classes.circleDark}`} />
      <div className={`${classes.circle} ${classes.circleLight}`} />
      <h1 className={`${classes.text} text-lg`}>
        <Link className="text-white no-underline" to="/orders">
          ProjSim
        </Link>
      </h1>
    </Grid>
  );
};
