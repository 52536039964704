import { orderActions as actions } from 'store/actions';
import { createReducer, updateEntry, initState } from 'store/reducers/utilities';

export const orderReducer = createReducer(initState(), {
  [actions.FETCH_ALL_ORDERS]: updateEntry,
  [actions.FETCH_ORDER]: updateEntry,
  [actions.UPDATE_ORDER]: updateEntry,
  [actions.DELETE_ORDER]: updateEntry,
  [actions.CREATE_ORDER]: updateEntry,
});
